import { useEffect, useState } from "react";
import { iVendaAPI } from "../../../../servicos/intencaovenda";
import React, {useRef} from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ListagemFeedback from "../../../Listagem/feedback";
import ListagemOpcoes from "../../../Listagem/opcoes";
import Topo from "../../../Topo/topo";
import Paginacao from "../../../Listagem/paginacao";

function TabelaRelatorioIntencaoVenda() {

    const date = new Date();

    const breadcrumbs = ['Home','Relatório Intenção de Venda'];
    const [PesquisaRelatorioIntencaoVenda, setPesquisaRelatorioIntencaoVenda] = useState([]);
    
    const [exibeErro, setExibeErro] = useState(false);    
    const [mensagemErro, setMensagemErro] = useState('');    

    const [mes, setmes] = useState(date.getMonth()+1);
    const [ano, setano] = useState(date.getFullYear());

    const tableRef = useRef(null);

    useEffect(() => {
        fetchIntencaoVenda(mes,ano);
    }, []);

    async function fetchIntencaoVenda(fMes,fAno){

        iVendaAPI.get(`/relatorio/${fMes}/${fAno}`)
        .then(resposta => {
            setPesquisaRelatorioIntencaoVenda(resposta.data);
        })
        .catch(erro => {          
            if(erro?.resposta?.data?.message){
                setExibeErro(true);
                setMensagemErro(erro.resposta.data.message);
            }else{
                if (erro.response?.status === 401) {
                    setExibeErro(true);
                    setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                  }else{
                    setExibeErro(true);
                    setMensagemErro('Ops! Ocorreu um erro inesperado.');
                  }                                           
            }        
        })               
        
    }        

    function FormatarDataHora(string){

        var retorno = '';
        if(string !== null){
            string = string.split("T");        
            var dataaux = string[0].split("-");
            var dataformatada = dataaux[2] + '/' + dataaux[1] + "/" + dataaux[0];      
            retorno = dataformatada + ' ' + string[1].substring(0,5) ;    
        }

        return retorno;

    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Relatório Intenção de Venda' breadcrumbs={breadcrumbs} btnincluir={false} admin={true}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={false}
                    mensagemSucesso={''}
                />
            </div>
            <ListagemOpcoes
                esconderTamanhoPagina={true}
                esconderOpcaoFiltro={true}
                esconderCampoFiltro={true}
            /> 
        <div class="row row-padding-conteudo" style={{marginBottom: '10px'}}>
            <div class="col-md-3 form-floating">
                <select class="form-select" aria-label="Selecione" id="mes" name="mes" onChange={evento => {setmes(evento.target.value);fetchIntencaoVenda(evento.target.value,ano);}} value={mes} >
                    <option value="1">Janeiro</option>
                    <option value="2">Fevereiro</option>
                    <option value="3">Março</option>
                    <option value="4">Abril</option>
                    <option value="5">Maio</option>
                    <option value="6">Junho</option>
                    <option value="7">Julho</option>
                    <option value="8">Agosto</option>
                    <option value="9">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                </select>
                <label for="floatingInput">Mês</label>
            </div>
            <div class="col-md-2 form-floating">
                <select class="form-select" aria-label="Selecione" id="ano" name="ano" onChange={evento => {setano(evento.target.value);fetchIntencaoVenda(mes,evento.target.value);}} value={ano} >
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
                <label for="floatingInput">Ano</label>
            </div>
            <div class="col-md-6 form-floating">
                &nbsp;
            </div>
            <div class="col-md-1 form-floating" style={{paddingTop: '15px'}}>
                <DownloadTableExcel filename="ConexaoDetranPR_IntencaoVenda" sheet="Intencao de Venda" currentTableRef={tableRef.current} >
                    <span class="material-symbols-outlined">cloud_download</span><span>Excel</span>
                </DownloadTableExcel>            
            </div>

        </div>    
            <div class="row row-padding-conteudo" >
                <div class="col-12 mobile-hide tablet-hide">
                    <div class="table-responvive">
                        <table class="table" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th class="topo" scope="col">Placa</th>
                                    <th class="topo" scope="col">Renavam</th>
                                    <th class="topo" scope="col">CRV</th>
                                    <th class="topo" scope="col">Documento Comprador</th>
                                    <th class="topo" scope="col">Comprador</th>
                                    <th class="topo" scope="col">Valor</th>
                                    <th class="topo" scope="col">ATPVe</th>
                                    <th class="topo" scope="col">Data Cadastro</th>
                                    <th class="topo" scope="col">Situação</th>
                                    <th class="topo" scope="col">Data Situação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {PesquisaRelatorioIntencaoVenda.map(ivenda => (
                                    <tr>
                                        <td>
                                            {ivenda.ve103_dsc_placa}
                                        </td>
                                        <td>
                                            {ivenda.ve103_num_renavam}
                                        </td>
                                        <td>
                                            {ivenda.ve103_num_crv}
                                        </td>
                                        <td>
                                            {ivenda.ve103_num_documento_comprador}
                                        </td>
                                        <td>
                                            {ivenda.ve103_dsc_nome_comprador}
                                        </td>
                                        <td>
                                            {ivenda.ve103_vlr_venda}
                                        </td>
                                        <td>
                                            {ivenda.ve103_num_atpve}
                                        </td>
                                        <td>
                                            {FormatarDataHora(ivenda.createdAt)}
                                        </td>
                                        <td>
                                            {ivenda.ve103_dsc_situacao}
                                        </td>
                                        <td>
                                            {FormatarDataHora(ivenda.ve103_dat_situacao)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Paginacao esconder={true} />
        </div>
    )

}

export default TabelaRelatorioIntencaoVenda;