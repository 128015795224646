import { useEffect, useState } from "react";
import { AdminEmplacarAPI } from "../../../servicos/Admin/emplacar";
import { useNavigate, Link } from "react-router-dom";
import Topo from "../../Topo/topo";
import ListagemFeedback from "../../Listagem/feedback";
import ListagemOpcoes from "../../Listagem/opcoes";
import Paginacao from "../../Listagem/paginacao";
import UsuarioCard from "./card";


function EmplacarTabela() {

    const breadcrumbs = ['Admin', 'Emplacar'];

    const [ve101_cod_emplacar, set101_cod_emplacar] = useState('');
    const [ve101_key_nfe, set101_key_nfe] = useState('');
    const [ve101_dsc_chassi, set101_dsc_chassi] = useState('');
    const [ve101_key_public, set101_key_public] = useState('');

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [PesquisaEmplacar, setPesquisaEmplacar] = useState([]);

    useEffect(() => {
        //fetchEmplacamentos(ve101_cod_emplacar,ve101_dsc_chassi,ve101_key_nfe);
    }, []);

    async function fetchEmplacamentos(ve101_cod_emplacar,ve101_dsc_chassi,ve101_key_nfe) {

        if ((ve101_cod_emplacar === '') && (ve101_dsc_chassi === '') && (ve101_key_nfe === ''))
        {
            setExibeSucesso(false);
            setMensagemSucesso('');
    
            setExibeErro(true);
            setMensagemErro('Favor Informar um filtro para consulta.');

        }else{

            if (ve101_cod_emplacar === ''){
                ve101_cod_emplacar = '0';
            }

            if (ve101_dsc_chassi === ''){
                ve101_dsc_chassi = '0';
            }

            if (ve101_key_nfe === ''){
                ve101_key_nfe = '0';
            }
    
            AdminEmplacarAPI.get(`/${ve101_cod_emplacar}/${ve101_dsc_chassi}/${ve101_key_nfe}`)
            .then(resposta => {
                setPesquisaEmplacar(resposta.data);
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
            })

        }

    }

    let navigate = useNavigate();

    async function Reativar(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        AdminEmplacarAPI.patch(`/reativar/${key}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Emplacamento Reativado com sucesso.');
                fetchEmplacamentos(ve101_cod_emplacar,ve101_dsc_chassi,ve101_key_nfe);
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })
    }
    
    async function Reabrir(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        AdminEmplacarAPI.patch(`/Reabrir/${key}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Emplacamento Reaberto com sucesso.');
                fetchEmplacamentos(ve101_cod_emplacar,ve101_dsc_chassi,ve101_key_nfe);
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })
    }

    async function LimparProcesso(id) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        AdminEmplacarAPI.patch(`/LimparProcesso/${id}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Número de Processo apagado com sucesso.');
                fetchEmplacamentos(ve101_cod_emplacar,ve101_dsc_chassi,ve101_key_nfe);
            })
            .catch(erro => {
                if (erro?.response?.data?.message) {
                    setExibeErro(true);
                    if ((erro.response?.status === 401) || ((erro.response?.status === 406) && erro?.response?.data?.message === "Token Inválido") ) {                        
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setMensagemErro(erro.response.data.message);
                    }
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                //fetchEmplacamentos();
            })

    }

    async function Deletar(id) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        AdminEmplacarAPI.delete(`/Deletar/${id}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Emplacamento Apagado com Sucesso.');
                fetchEmplacamentos(ve101_cod_emplacar,ve101_dsc_chassi,ve101_key_nfe);
            })
            .catch(erro => {
                setExibeErro(true);
                if (erro?.response?.data?.message) {                    
                    if ((erro.response?.status === 401) || ((erro.response?.status === 406) && erro?.response?.data?.message === "Token Inválido") ) {                        
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setMensagemErro(erro.response.data.message);
                    }
                } else {
                    if (erro.response?.status === 401) {
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                //fetchEmplacamentos();
            })                


    }    
    
    function FormatarDataHora(string){

        var retorno = '';
        if(string !== null){
            string = string.split("T");        
            var dataaux = string[0].split("-");
            var dataformatada = dataaux[2] + '/' + dataaux[1] + "/" + dataaux[0];      
            retorno = dataformatada + ' ' + string[1].substring(0,5) ;    
        }

        return retorno;

    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Administração do Emplacar' breadcrumbs={breadcrumbs} btnincluir={false} destino='' admin={false} />
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
            </div>
            <ListagemOpcoes
                esconderTamanhoPagina={true}
                esconderOpcaoFiltro={true}
                esconderCampoFiltro={true}
            />
        <div class="row row-padding-conteudo" style={{marginBottom: '10px'}}>
            <div class="col-md-3 form-floating">
                <input type="inputtext" class="form-control" id="ve101_cod_emplacar" placeholder="" value={ve101_cod_emplacar} onChange={evento => {set101_cod_emplacar(evento.target.value);}} />
                <label for="floatingInput">Código do Emplacamento</label>
            </div>
            <div class="col-md-3 form-floating">
                <input type="inputtext" class="form-control" id="ve101_dsc_chassi" placeholder="" name="ve101_dsc_chassi" value={ve101_dsc_chassi} onChange={evento => {set101_dsc_chassi(evento.target.value);}} />
                <label for="floatingInput">Chassi</label>
            </div>
            <div class="col-md-5 form-floating">
                <input type="inputtext" class="form-control" id="ve101_key_nfe" placeholder="" value={ve101_key_nfe} onChange={evento => {set101_key_nfe(evento.target.value);}} />
                <label for="floatingInput">Chave da NFs</label>
            </div>
            <div class="col-md-1 form-floating" style={{paddingTop: '20px'}} onClick={() => fetchEmplacamentos(ve101_cod_emplacar,ve101_dsc_chassi,ve101_key_nfe)}>
                <span class="material-symbols-outlined" title="Pesquisar">search</span>
            </div>

        </div>               
            <div class="row row-padding-conteudo" >
                <div class="col-12 mobile-hide tablet-hide">
                    <div class="table-responvive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="topo" scope="col">Filiado</th>
                                    <th class="topo" scope="col">Seq/Data</th>
                                    <th class="topo" scope="col">NF/Chassi</th>
                                    <th class="topo" scope="col">Processo</th>
                                    <th class="topo" scope="col">Placa/Renavam</th>
                                    <th class="topo" scope="col">Documentos</th>
                                    <th class="topo" scope="col">Status</th>
                                    <th class="topo" scope="col">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {PesquisaEmplacar.map(veiculo => (
                                    <tr>
                                        <td>
                                            {veiculo.fi100_filiado.fi100_dsc_filiado}
                                        </td>
                                        <td>
                                            {veiculo.ve101_cod_emplacar}<br />
                                            <span class="email">
                                                {FormatarDataHora(veiculo.createdAt)}
                                            </span>
                                        </td>
                                        <td>
                                            {veiculo.ve101_key_nfe}<br />
                                            <span class="email">
                                                {veiculo.ve101_dsc_chassi}
                                            </span>
                                        </td>
                                        <td>
                                            <a href={veiculo.ve101_url_processo} target="_blank">{veiculo.ve101_num_processo}</a><br />
                                        </td>
                                        <td>
                                            {veiculo.ve101_dsc_placa}<br />
                                            <span class="email">
                                                {veiculo.ve101_num_renavam}
                                            </span>
                                        </td>
                                        <td>
                                            {veiculo.ve101_url_autorizacao != null &&
                                                <a href={veiculo.ve101_url_autorizacao} target="_blank"><span class="material-symbols-outlined">Inventory</span></a>
                                            }
                                            {veiculo.ve101_url_crv != null &&
                                                <a href={veiculo.ve101_url_crv} target="_blank"><span class="material-symbols-outlined">Clarify</span></a>
                                            }
                                            {veiculo.ve101_url_seguranca != null &&
                                                <a href={veiculo.ve101_url_seguranca} target="_blank"><span class="material-symbols-outlined">Lock</span></a>
                                            }
                                        </td>
                                        <td>
                                            {veiculo.ve101_ind_desativado == true &&
                                                <span class="material-symbols-outlined" style={{color: "#dc3545"}} title="Deletado Filiado">delete_sweep</span>
                                            }                                            
                                            {veiculo.ve101_ind_finalizado == true &&
                                                <span class="material-symbols-outlined" style={{color: "#198754"}} title="Finalizado Filiado">done_all</span>
                                            }                                            
                                            {veiculo.ve101_num_processo != null && veiculo.ve101_url_seguranca == null &&
                                                <span class="material-symbols-outlined" style={{color: "#ffc107"}} title="Processo Criado/Aguardando Pagamento">contract</span>
                                            }                                            
                                            {veiculo.ve101_url_seguranca != null &&
                                                <span class="material-symbols-outlined" style={{color: "#0d6efd"}} title="Processo Finalizado Junto ao Detran">assured_workload</span>
                                            }
                                        </td>
                                        <td style={{verticalAlign:'bottom'}}>
                                            <div class="dropdown">
                                                <button class="dropdown-acao btn-dropdown btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="true">
                                                    <span class="material-symbols-outlined">more_vert</span>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li>
                                                        {veiculo.ve101_ind_desativado == true &&
                                                            <a class="dropdown-item align-icon " style={{color: "#198754",cursor: 'pointer'}} onClick={() => Reativar(veiculo.ve101_key_public)}>
                                                                <span class="material-symbols-outlined">restore_from_trash</span>Reativar
                                                            </a>
                                                        }
                                                        {veiculo.ve101_ind_desativado == true &&
                                                            <a class="dropdown-item align-icon action-disable" style={{cursor: 'pointer'}} onClick={() => Deletar(veiculo.ve101_key_public)}>
                                                                <span class="material-symbols-outlined">delete_forever</span>Excluir do Sistema
                                                            </a>
                                                        }
                                                        {veiculo.ve101_ind_desativado == false && veiculo.ve101_num_processo == null && veiculo.ve101_url_seguranca == null &&
                                                            <a class="dropdown-item align-icon action-disable" style={{cursor: 'pointer'}} onClick={() => Deletar(veiculo.ve101_key_public)}>
                                                                <span class="material-symbols-outlined">delete_forever</span>Excluir do Sistema
                                                            </a>
                                                        }
                                                        {veiculo.ve101_ind_finalizado == true &&
                                                            <a class="dropdown-item align-icon" style={{color: "#198754",cursor: 'pointer'}} onClick={() => Reabrir(veiculo.ve101_key_public)}>
                                                                <span class="material-symbols-outlined">remove_done</span>Reabrir para o Filiado
                                                            </a>
                                                        }
                                                        {veiculo.ve101_num_processo != null && veiculo.ve101_url_seguranca == null &&
                                                            <a class="dropdown-item align-icon" style={{color: "#ffc107",cursor: 'pointer'}} title="Desnvicula o Processo Detran da Solicitação Emplacamento" onClick={() => LimparProcesso(veiculo.ve101_key_public)}>
                                                                <span class="material-symbols-outlined">contract_delete</span>Limpar Processo
                                                            </a>
                                                        }
                                                        {veiculo.ve101_num_processo != null && veiculo.ve101_url_seguranca != null &&
                                                            <a class="dropdown-item align-icon" style={{color: "#0d6efd",cursor: 'pointer'}} title="Não pode ser Cancela/Reaberto">
                                                                <span class="material-symbols-outlined">done_outline</span>Emplacamento Finalizado
                                                            </a>
                                                        }

                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <UsuarioCard />
            <Paginacao esconder={true} />
        </div>
    )

}

export default EmplacarTabela;