function ListagemFeedback(props) {

    const mostraErro = props.displayErro;
    const mensagemErro = props.mensagemErro;

    const mostraSucesso = props.displaySucesso;
    const mensagemSucesso = props.mensagemSucesso;

    const mostraATPVe = props.displayATPVe;

    return (
        <div class="row row-padding-conteudo">
            {mostraATPVe &&
            <div class="col-12">
                <div class="alert alert-warning alert-w100" role="alert" style={{lineHeight:'28px'}}>
                    <span class="material-symbols-outlined" style={{verticalAlign: 'text-bottom'}} >warning</span>
                    <strong>ATENÇÃO: </strong>
                    Esse processo não é a comunicação de venda, mas sim o registro de intenção de venda.<br/>
                    O <strong>Registro de intenção de venda </strong>é a solicitação da Autorização para Transferência de Propriedade do Veículo Eletrônica (ATPV-e).<br/>
                    Para veículos com documento emitido a partir de 01/01/2021 é necessário solicitar a autorização antes de realizar o comunicado. <br/>
                    Esse processo substitui o preenchimento das informações no site do Detran-PR para solicitar o ATPV-e.<br/> 
                    <strong>A intenção de venda e a comunicação de venda são atos distintos.</strong><br/>
                    <a href="https://www.detran.pr.gov.br/Noticia/Documento-de-transferencia-de-veiculos-agora-pode-ser-impresso-em-casa" target="_blank">Saiba mais</a><br/>
                    <a href="https://www.detran.pr.gov.br/servicos/Veiculo/Compra-e-venda/Registrar-intencao-de-venda-do-veiculo-K03YMj34" target="_blank">Saiba mais 2</a>
                </div>
            </div>
            }
            {mostraSucesso &&
            <div class="col-12">
                <div class="alert alert-success alert-w100" role="alert">
                    <span class="material-symbols-outlined">error</span>{mensagemSucesso}
                </div>
            </div>
            }
            {mostraErro &&
                <div class="col-12">
                    <div class="alert alert-danger alert-w100" role="alert">
                        <span class="material-symbols-outlined">error</span>{mensagemErro}
                    </div>
                </div>
            }
        </div>
    )

}

export default ListagemFeedback;