import axios from "axios";
import { URL_BASE } from "./0_url_base";

//const URL = 'http://localhost:3001/admin/relatorio';
//const URL = 'https://conexaodetranpr.sindilocpr.com.br/api/admin/relatorio';
const URL = URL_BASE + '/admin/relatorio';

const relatorioAdmin = axios.create({
    baseURL: URL,
    headers: {
        Accept: 'application/json',
        Content: 'application/json'
    }
})

relatorioAdmin.interceptors.request.use(function (config) {
    const Token = sessionStorage.getItem('token');
    if((Token) && (config.headers)){
        config.headers.Authorization = `Bearer ${Token}`
    }
    return config;
}, function (error) {
    console.log("Erro na API dos Relatorios Administrativos");
    return Promise.reject(error);
});


relatorioAdmin.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});


export {
    relatorioAdmin
};