import axios from "axios";
import { URL_BASE } from "./0_url_base";

//const URL = 'http://localhost:3001/filiado/emplacar';
//const URL = 'https://conexaodetranpr.sindilocpr.com.br/api/filiado/emplacar';
const URL = URL_BASE + '/filiado/emplacar';

const emplacarAPI = axios.create({
    baseURL: URL,
    headers: {
        Accept: 'application/json',
        Content: 'application/json'
    }
})

emplacarAPI.interceptors.request.use(function (config) {
    const Token = sessionStorage.getItem('token');
    if((Token) && (config.headers)){
        config.headers.Authorization = `Bearer ${Token}`
    }
    return config;
}, function (error) {
    console.log("Erro na API do Veículo");
    return Promise.reject(error);
});


emplacarAPI.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});


export {
    emplacarAPI
};