import Listagem from '../../componentes/Admin/Emplacar/Listagem';
//import Cadastro from '../../componentes/Admin/Emplacar/Cadastro';

function AdminEmplacar(props) {  
  
  return (

    <section>

      { props.acao === "Listar" &&        
        <Listagem />
      }
      { props.acao !== "Listar" &&
        <div>456</div>
        //<Cadastro acao={props.acao} />
      }    

    </section>

  );

}

export default AdminEmplacar;
