import { useEffect, useState } from "react";
import { relatorioAdmin } from "../../servicos/relatorioAdmin";
import { filiadoAPI } from "../../servicos/filiado";
import React, {useRef} from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ListagemFeedback from "../Listagem/feedback";
import ListagemOpcoes from "../Listagem/opcoes";
import Topo from "../Topo/topo";
import Paginacao from "../Listagem/paginacao";

function TabelaRelatorioEmplacamento() {

    const date = new Date();

    const breadcrumbs = ['Admin','Rel. Emplacamento'];
    const [PesquisaRelatorioEmplacamento, setPesquisaRelatorioEmplacamento] = useState([]);
    const [PesquisaFiliados, setPesquisaFiliados] = useState([]);

    const [exibeErro, setExibeErro] = useState(false);    
    const [mensagemErro, setMensagemErro] = useState('');    

    const [exibeSucesso, setExibeSucesso] = useState(false);    
    const [mensagemSucesso, setMensagemSucesso] = useState('');    

    const [fi100_key_public, set100_key_public] = useState('0');
    const [mes, setmes] = useState(date.getMonth()+1);
    const [ano, setano] = useState(date.getFullYear());

    const tableRef = useRef(null);

    useEffect(() => {
        fetchFiliados();
        fetchEmplacamentos(fi100_key_public,mes,ano);
    }, []);

    async function fetchEmplacamentos(fKey,fMes,fAno){

        relatorioAdmin.get(`/emplacamento/${fKey}/${fMes}/${fAno}`)
        .then(resposta => {
            setPesquisaRelatorioEmplacamento(resposta.data);
        })
        .catch(erro => {          
            if(erro?.resposta?.data?.message){
                setExibeErro(true);
                setMensagemErro(erro.resposta.data.message);
            }else{
                if (erro.response?.status === 401) {
                    setExibeErro(true);
                    setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                  }else{
                    setExibeErro(true);
                    setMensagemErro('Ops! Ocorreu um erro inesperado.');
                  }                                           
            }        
        })               
        
    }        

    async function fetchFiliados(){

        filiadoAPI.get('/')
        .then(resposta => {
            setPesquisaFiliados(resposta.data);
        })
        .catch(erro => {          
            if(erro?.resposta?.data?.message){
                setExibeErro(true);
                setMensagemErro(erro.resposta.data.message);
            }else{
                if (erro.response?.status === 401) {
                    setExibeErro(true);
                    setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                  }else{
                    setExibeErro(true);
                    setMensagemErro('Ops! Ocorreu um erro inesperado.');
                  }                                           
            }        
        })               
        
    }        


    function FormatarDataHora(string){

        var retorno = '';
        if(string !== null){
            string = string.split("T");        
            var dataaux = string[0].split("-");
            var dataformatada = dataaux[2] + '/' + dataaux[1] + "/" + dataaux[0];      
            retorno = dataformatada + ' ' + string[1].substring(0,5) ;    
        }

        return retorno;

    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Relatório Emplacamento' breadcrumbs={breadcrumbs} btnincluir={false} admin={true}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
            </div>
            <ListagemOpcoes
                esconderTamanhoPagina={true}
                esconderOpcaoFiltro={true}
                esconderCampoFiltro={true}
            /> 
        <div class="row row-padding-conteudo" style={{marginBottom: '10px'}}>
            <div class="col-md-6 form-floating">
                <select class="form-select" aria-label="Selecione" id="fi100_key_public" name="fi100_key_public" onChange={evento => {set100_key_public(evento.target.value);fetchEmplacamentos(evento.target.value,mes,ano);}} value={fi100_key_public} >
                    <option value="0">Selecione</option>
                    {PesquisaFiliados.map(filiado => (
                        <option value={filiado.fi100_key_public}>{filiado.fi100_dsc_filiado}</option>
                    ))}
                </select>
                <label for="floatingInput">Filiado</label>
            </div>
            <div class="col-md-3 form-floating">
                <select class="form-select" aria-label="Selecione" id="mes" name="mes" onChange={evento => {setmes(evento.target.value);fetchEmplacamentos(fi100_key_public,evento.target.value,ano);}} value={mes} >
                    <option value="1">Janeiro</option>
                    <option value="2">Fevereiro</option>
                    <option value="3">Março</option>
                    <option value="4">Abril</option>
                    <option value="5">Maio</option>
                    <option value="6">Junho</option>
                    <option value="7">Julho</option>
                    <option value="8">Agosto</option>
                    <option value="9">Setembro</option>
                    <option value="10">Outubro</option>
                    <option value="11">Novembro</option>
                    <option value="12">Dezembro</option>
                </select>
                <label for="floatingInput">Mês</label>
            </div>
            <div class="col-md-2 form-floating">
                <select class="form-select" aria-label="Selecione" id="ano" name="ano" onChange={evento => {setano(evento.target.value);fetchEmplacamentos(fi100_key_public,mes,evento.target.value);}} value={ano} >
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
                <label for="floatingInput">Ano</label>
            </div>
            <div class="col-md-1 form-floating" style={{paddingTop: '15px'}}>
                <DownloadTableExcel filename="ConexaoDetranPR" sheet="Emplacamento" currentTableRef={tableRef.current} >
                    <span class="material-symbols-outlined">cloud_download</span><span>Excel</span>
                </DownloadTableExcel>            
            </div>

        </div>    
            <div class="row row-padding-conteudo" >
                <div class="col-12 mobile-hide tablet-hide">
                    <div class="table-responvive">
                        <table class="table" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th class="topo" scope="col">Filiado</th>
                                    <th class="topo" scope="col">Placa</th>
                                    <th class="topo" scope="col">Chassi</th>
                                    <th class="topo" scope="col">Renavam</th>
                                    <th class="topo" scope="col">Emplacamento</th>
                                    <th class="topo" scope="col">Processo</th>
                                    <th class="topo" scope="col">Data Cadastro</th>
                                    <th class="topo" scope="col">Situação</th>
                                    <th class="topo" scope="col">Data Situação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {PesquisaRelatorioEmplacamento.map(emplacamento => (
                                    <tr>
                                        <td>
                                            {emplacamento.fi100_filiado.fi100_dsc_filiado}
                                        </td>
                                        <td>
                                            {emplacamento.ve101_dsc_placa}
                                        </td>
                                        <td>
                                            {emplacamento.ve101_dsc_chassi}
                                        </td>
                                        <td>
                                            {emplacamento.ve101_num_renavam}
                                        </td>
                                        <td>
                                            {emplacamento.ve101_cod_emplacar}
                                        </td>
                                        <td>
                                            {emplacamento.ve101_num_processo}
                                        </td>
                                        <td>
                                            {FormatarDataHora(emplacamento.createdAt)}
                                        </td>
                                        <td>
                                            {emplacamento.ve101_dsc_situacao}
                                        </td>
                                        <td>
                                            {FormatarDataHora(emplacamento.ve101_dat_situacao)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Paginacao esconder={true} />
        </div>
    )

}

export default TabelaRelatorioEmplacamento;