import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import { iVendaAPI } from "../../servicos/intencaovenda";
import { InputMask } from '@react-input/mask';
import CurrencyInput from 'react-currency-input-field';

function Incluir() {

    const breadcrumbs = ['Home', 'Intenção de Venda', 'Novo'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');
    
    const [ve103_key_public, set103_key_public] = useState('');
    const [ve103_dsc_placa, set103_dsc_placa] = useState('');
    const [ve103_num_renavam, set103_num_renavam] = useState('');
    const [ve103_vlr_venda, set103_vlr_venda] = useState('');
    const [ve103_num_crv, set103_num_crv] = useState('');
    const [ve103_num_hodometro, set103_num_hodometro] = useState('');
    const [ve103_num_documento_vendedor, set103_num_documento_vendedor] = useState('');
    const [ve103_dsc_email_vendedor, set103_dsc_email_vendedor] = useState('');
    const [ve103_num_documento_comprador, set103_num_documento_comprador] = useState('');
    const [ve103_dsc_nome_comprador, set103_dsc_nome_comprador] = useState('');
    const [ve103_dsc_email_comprador, set103_dsc_email_comprador] = useState('');
    const [ve103_dsc_uf_comprador, set103_dsc_uf_comprador] = useState('');
    const [ve103_num_cep_comprador, set103_num_cep_comprador] = useState('');
    const [ve103_dsc_rua_comprador, set103_dsc_rua_comprador] = useState('');
    const [ve103_num_rua_comprador, set103_num_rua_comprador] = useState('');
    const [ve103_dsc_complemento_comprador, set103_dsc_complemento_comprador] = useState('');
    const [ve103_dsc_bairro_comprador, set103_dsc_bairro_comprador] = useState('');

    const [mostraSalvar, setmostraSalvar] = useState(true);
    const [mostraEnviar, setmostraEnviar] = useState(false);
    const [mostraDownload, setmostraDownload] = useState(false);
    const [mostraNovo, setmostraNovo] = useState(false);  

    let navigate = useNavigate();

    const Voltar = () => {
        let path = '/filiado/ivenda';
        navigate(path);
    }

    const NovoCadastro = () => {

        setExibeErro(false);
        setMensagemErro('');    
        setExibeSucesso(false);
        setMensagemSucesso('');    
        
        set103_dsc_placa('');
        set103_num_renavam('');
        set103_key_public('');

        setmostraSalvar(true);
        setmostraEnviar(false);
        setmostraDownload(false);
        setmostraNovo(false);  

    }

    const validaForm = (event) => {
        event.preventDefault();

        setmostraSalvar(false);

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        if (ve103_dsc_placa === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar a Placa do Veículo.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_num_renavam === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o Renavam.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_num_crv === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o CRV.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_num_hodometro === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o Hodometro.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_vlr_venda === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o Valor de Venda.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_num_documento_vendedor === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o CNPJ do Vendedor.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_dsc_email_vendedor === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o E-mail do Vendedor.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_num_documento_comprador === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o Documento do Comprador.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_dsc_nome_comprador === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o nome do Comprador.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_dsc_email_comprador === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o E-mail do Comprador.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_dsc_uf_comprador === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o Estado do Comprador.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_num_cep_comprador === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o CEP do Comprador.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_dsc_rua_comprador === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar a Rua do Comprador.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_num_rua_comprador === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar a Numero do Endereço do Comprador.");
            setmostraSalvar(true);
            return false;
        }

        if (ve103_dsc_bairro_comprador === '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o Bairro do Comprador.");
            setmostraSalvar(true);
            return false;
        }

        RegistrarIntencao();
    }

    async function RegistrarIntencao() {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        iVendaAPI.post('/',
            {
                've103_dsc_placa': ve103_dsc_placa.replace( /\s/g,''),
                've103_num_renavam': ve103_num_renavam.replace( /\s/g,''),
                've103_num_crv': ve103_num_crv.replace( /\s/g,''),
                've103_num_hodometro': ve103_num_hodometro.replace( /\s/g,''),
                've103_vlr_venda': ve103_vlr_venda.replace( /\s/g,'').replace(/\$/g,'').replace(/R/g,'').replace(/\./g,'').replace(/\,/g,'.'),
                've103_num_documento_vendedor': ve103_num_documento_vendedor.replace( /\s/g,'').replace(/\./g,'').replace(/\-/g,'').replace(/\//g,''),
                've103_dsc_email_vendedor': ve103_dsc_email_vendedor.replace( /\s/g,''),
                've103_num_documento_comprador': ve103_num_documento_comprador.replace( /\s/g,'').replace(/\./g,'').replace(/\-/g,'').replace(/\//g,''),
                've103_dsc_nome_comprador': ve103_dsc_nome_comprador,
                've103_dsc_email_comprador': ve103_dsc_email_comprador.replace( /\s/g,''),
                've103_dsc_uf_comprador': ve103_dsc_uf_comprador,
                've103_dsc_rua_comprador': ve103_dsc_rua_comprador,
                've103_num_rua_comprador': ve103_num_rua_comprador,
                've103_dsc_complemento_comprador': ve103_dsc_complemento_comprador,
                've103_dsc_bairro_comprador': ve103_dsc_bairro_comprador,        
                've103_num_cep_comprador': ve103_num_cep_comprador.replace( /\s/g,'').replace(/\./g,'').replace(/\-/g,''),
            })
            .then(resposta => {
                set103_key_public(resposta.data.ivenda.ve103_key_public)
                setExibeSucesso(true);
                setMensagemSucesso('Registo criado com sucesso. Agora clique no botão DetranPR, para registrar a intenção de venda do seu veículo.');
                setmostraEnviar(true);
            })
            .catch(erro => {
                if (erro?.response?.data?.message) {
                    setExibeErro(true);
                    if (erro.response?.status === 401) {
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    }else{
                        setMensagemErro(erro.response.data.message);
                    }                    
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                setmostraSalvar(true);
            })

    }

    const EnviarDetran = (event) => {
        event.preventDefault();

        setmostraEnviar(false);

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        RegistrarIntencaoVenda();
    }

    async function RegistrarIntencaoVenda() {

        iVendaAPI.get(`/processar/${ve103_key_public}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('ATPVe (' + resposta.data.ivenda.ve103_num_atpve + ') registrado com sucesso.');
                setmostraNovo(true);
            })
            .catch(erro => {
                if (erro?.response?.data?.message) {
                    setExibeErro(true);
                    if ((erro.response?.status === 401) || ((erro.response?.status === 406) && erro?.response?.data?.message === "Token Inválido") ) {
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    }else{
                        setMensagemErro(erro.response.data.message);
                    }                    
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                setmostraEnviar(true);
            })

    }


    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Intenção de Venda' breadcrumbs={breadcrumbs} btnincluir={false} admin={false} />
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
                <div class="row row-padding-conteudo">
                    <div class="col-12">
                        <form class="row g-3" onSubmit={validaForm}>
                            <input type="hidden" class="form-control" id="ve103_key_public" placeholder="" value={ve103_key_public} onChange={evento => set103_key_public(evento.target.value)} />
                            <div class="col-12">
                            <div class="accordion accordion-flush" id="pnlIntencaoVenda">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" aria-expanded="true" style={{backgroundColor:"#cfe2ff"}}>
                                            <strong>Veículo</strong>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse show" data-bs-parent="#pnlIntencaoVenda">
                                        <div class="accordion-body">
                                            <div class="row row-padding-conteudo">
                                                <div class="col-md-3 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_dsc_placa" placeholder="" value={ve103_dsc_placa} onChange={evento => set103_dsc_placa(evento.target.value)} maxLength={7}/>
                                                    <label for="floatingInput">Placa</label>
                                                </div>
                                                <div class="col-md-3 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_num_renavam" placeholder="" name="ve103_num_renavam" value={ve103_num_renavam} onChange={evento => set103_num_renavam(evento.target.value)} maxLength={11}/>
                                                    <label for="floatingInput">Renavam</label>
                                                </div>
                                                <div class="col-md-3 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_num_crv" placeholder="" name="ve103_num_crv" value={ve103_num_crv} onChange={evento => set103_num_crv(evento.target.value)} maxLength={13}/>
                                                    <label for="floatingInput">CRV</label>
                                                </div>
                                            </div>
                                            <div class="row row-padding-conteudo" style={{paddingTop:'20px'}}>
                                                <div class="col-md-3 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_num_hodometro" placeholder="" name="ve103_num_hodometro" value={ve103_num_hodometro} onChange={evento => set103_num_hodometro(evento.target.value)} maxLength={8}/>
                                                    <label for="floatingInput">Hodometro</label>
                                                </div>                                            
                                                <div class="col-md-6 form-floating">
                                                    <CurrencyInput class="form-control" id="ve103_vlr_venda" name="ve103_vlr_venda" intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} allowNegativeValue="false" disableAbbreviations="true" maxLength={13} onChange={evento => set103_vlr_venda(evento.target.value)} />                                            
                                                    <label for="floatingInput">Valor</label>
                                                </div>
                                            </div>      
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" aria-expanded="true" style={{backgroundColor:"#cfe2ff"}}>
                                        <strong>Vendedor</strong>
                                    </button>
                                    </h2>
                                    <div id="flush-collapseTwo" class="accordion-collapse collapse show" data-bs-parent="#pnlIntencaoVenda">
                                        <div class="accordion-body">
                                            <div class="row row-padding-conteudo">
                                                <div class="col-md-3 form-floating">
                                                    <InputMask mask="__.___.___/____-__" replacement={{ _: /\d/ }} class="form-control" id="ve103_num_documento_vendedor" value={ve103_num_documento_vendedor} onChange={evento => set103_num_documento_vendedor(evento.target.value)} />
                                                    <label for="floatingInput">CNPJ</label>
                                                </div>
                                                <div class="col-md-9 form-floating">
                                                    <input type="email" class="form-control" id="ve103_dsc_email_vendedor" placeholder="name@example.com.br" name="ve103_dsc_email_vendedor" value={ve103_dsc_email_vendedor} onChange={evento => set103_dsc_email_vendedor(evento.target.value)} />
                                                    <label for="floatingInput">E-mail</label>
                                                </div>
                                            </div>      
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" aria-expanded="true" style={{backgroundColor:"#cfe2ff"}}>
                                        <strong>Comprador</strong>
                                    </button>
                                    </h2>
                                    <div id="flush-collapseThree" class="accordion-collapse collapse show" data-bs-parent="#pnlIntencaoVenda">
                                        <div class="accordion-body">
                                            <div class="row row-padding-conteudo">
                                                <div class="col-md-3 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_num_documento_comprador" placeholder="" name="ve103_num_documento_comprador" value={ve103_num_documento_comprador} onChange={evento => set103_num_documento_comprador(evento.target.value)} maxLength={14}/>
                                                    <label for="floatingInput">CPF/CNPJ</label>
                                                </div>
                                                <div class="col-md-9 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_dsc_nome_comprador" placeholder="" name="ve103_dsc_nome_comprador" value={ve103_dsc_nome_comprador} onChange={evento => set103_dsc_nome_comprador(evento.target.value)} maxLength={300}/>
                                                    <label for="floatingInput">Nome Completo</label>
                                                </div>
                                            </div>
                                            <div class="row row-padding-conteudo" style={{paddingTop:'20px'}}>
                                                <div class="col-md-8 form-floating">
                                                    <input type="email" class="form-control" id="ve103_dsc_email_comprador" placeholder="name@example.com.br" name="ve103_dsc_email_comprador" value={ve103_dsc_email_comprador} onChange={evento => set103_dsc_email_comprador(evento.target.value)} maxLength={300}/>
                                                    <label for="floatingInput">E-mail</label>
                                                </div>
                                                <div class="col-md-4 form-floating">
                                                    <select class="form-select" aria-label="Selecione" id="ve103_dsc_uf_comprador" name="ve103_dsc_uf_comprador" onChange={evento => set103_dsc_uf_comprador(evento.target.value)}>
                                                        <option selected value="">Selecione</option>
                                                        <option value="AC">AC</option>
                                                        <option value="AL">AL</option>
                                                        <option value="AP">AP</option>
                                                        <option value="AM">AM</option>
                                                        <option value="BA">BA</option>
                                                        <option value="CE">CE</option>
                                                        <option value="DF">DF</option>
                                                        <option value="ES">ES</option>
                                                        <option value="GO">GO</option>
                                                        <option value="MA">MA</option>
                                                        <option value="MT">MT</option>
                                                        <option value="MS">MS</option>
                                                        <option value="MG">MG</option>
                                                        <option value="PA">PA</option>
                                                        <option value="PB">PB</option>
                                                        <option value="PR">PR</option>
                                                        <option value="PE">PE</option>
                                                        <option value="PI">PI</option>
                                                        <option value="RJ">RJ</option>
                                                        <option value="RN">RN</option>
                                                        <option value="RS">RS</option>
                                                        <option value="RO">RO</option>
                                                        <option value="RR">RR</option>
                                                        <option value="SC">SC</option>
                                                        <option value="SP">SP</option>
                                                        <option value="SE">SE</option>
                                                        <option value="TO">TO</option>
                                                    </select>                                                    
                                                    <label for="floatingInput">UF</label>
                                                </div>
                                            </div>
                                            <div class="row row-padding-conteudo" style={{paddingTop:'20px'}}>
                                                <div class="col-md-8 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_dsc_rua_comprador" placeholder="" name="ve103_dsc_rua_comprador" value={ve103_dsc_rua_comprador} onChange={evento => set103_dsc_rua_comprador(evento.target.value)} maxLength={300}/>
                                                    <label for="floatingInput">Rua</label>
                                                </div>
                                                <div class="col-md-4 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_num_rua_comprador" placeholder="" name="ve103_num_rua_comprador" value={ve103_num_rua_comprador} onChange={evento => set103_num_rua_comprador(evento.target.value)} maxLength={30}/>
                                                    <label for="floatingInput">Número</label>
                                                </div>
                                            </div>
                                            <div class="row row-padding-conteudo" style={{paddingTop:'20px'}}>
                                                <div class="col-md-4 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_dsc_complemento_comprador" placeholder="" name="ve103_dsc_complemento_comprador" value={ve103_dsc_complemento_comprador} onChange={evento => set103_dsc_complemento_comprador(evento.target.value)} maxLength={120}/>
                                                    <label for="floatingInput">Complemento</label>
                                                </div>
                                                <div class="col-md-4 form-floating">
                                                    <input type="inputtext" class="form-control" id="ve103_dsc_bairro_comprador" placeholder="" name="ve103_dsc_bairro_comprador" value={ve103_dsc_bairro_comprador} onChange={evento => set103_dsc_bairro_comprador(evento.target.value)} maxLength={120}/>
                                                    <label for="floatingInput">Bairro</label>
                                                </div>
                                                <div class="col-md-4 form-floating">
                                                    <InputMask mask="__.___-___" replacement={{ _: /\d/ }} class="form-control" id="ve103_num_cep_comprador" name="ve103_num_cep_comprador" value={ve103_num_cep_comprador} onChange={evento => set103_num_cep_comprador(evento.target.value)} />
                                                    <label for="floatingInput">CEP</label>
                                                </div>
                                            </div>      
                                        </div>
                                    </div>
                                </div>
                                </div>                                
                            </div>
                            


                            <div class="col-12 text-end cadastro-btn">
                                <button type="button" class="btn btn-desativar btn-primary" onClick={Voltar}>Voltar</button>
                                {mostraSalvar &&
                                    <button type="submit" class="btn btn-primary-blue btn-primary">Salvar</button>
                                }
                                {mostraEnviar &&
                                    <button type="button" class="btn btn-primary-blue btn-primary" onClick={EnviarDetran}>DetranPR</button>
                                }
                                {mostraNovo &&
                                    <button type="button" class="btn btn-primary-blue btn-primary" onClick={NovoCadastro}>Novo</button>
                                }                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Incluir;

