import { useEffect, useState } from "react";
import { iVendaAPI } from "../../servicos/intencaovenda";
import { useNavigate } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import ListagemOpcoes from "../Listagem/opcoes";
import Paginacao from "../Listagem/paginacao";
import UsuarioCard from "./card";

function IVendaTabela() {

    const breadcrumbs = ['Home', 'Intenção de Venda'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [PesquisaIntencaoVenda, setPesquisaIntencaoVenda] = useState([]);

    useEffect(() => {
        fetchIntencaoVenda()
    }, []);

    async function fetchIntencaoVenda() {

        const fi100_key_public = sessionStorage.getItem('key');
        iVendaAPI.get(`/${fi100_key_public}`)
            .then(resposta => {
                setPesquisaIntencaoVenda(resposta.data);
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
            })

    }

    let navigate = useNavigate();

    async function RegistrarIntencaoVenda(id) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        iVendaAPI.get(`/processar/${id}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('ATPVe (' + resposta.data.ivenda.ve103_num_atpve + ') registrado com sucesso.');
                fetchIntencaoVenda();
            })
            .catch(erro => {
                if (erro?.response?.data?.message) {
                    setExibeErro(true);
                    if ((erro.response?.status === 401) || ((erro.response?.status === 406) && erro?.response?.data?.message === "Token Inválido") ) {
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    }else{
                        setMensagemErro(erro.response.data.message);
                    }                    
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
                fetchIntencaoVenda();
            })

    }    

    function Editar(key){
        let path = `/editar/${key}`;
        navigate(path);
    }    


    function FormatarDataHora(string){

        var retorno = '';
        if(string !== null){
            string = string.split("T");        
            var dataaux = string[0].split("-");
            var dataformatada = dataaux[2] + '/' + dataaux[1] + "/" + dataaux[0];      
            retorno = dataformatada + ' ' + string[1].substring(0,5) ;    
        }

        return retorno;

    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Intenção de Venda' breadcrumbs={breadcrumbs} btnincluir={true} destino='/filiado/ivenda/novo' admin={false} />
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                    displayATPVe={true}
                />
            </div>
            <ListagemOpcoes
                esconderTamanhoPagina={true}
                esconderOpcaoFiltro={true}
                esconderCampoFiltro={true}
            />
            <div class="row row-padding-conteudo" >
                <div class="col-12 mobile-hide tablet-hide">
                    <div class="table-responvive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="topo" scope="col">Registro</th>
                                    <th class="topo" scope="col">Placa/Renavam</th>
                                    <th class="topo" scope="col">ATPVe</th>
                                    <th class="topo" scope="col">Data/Status</th>                                    
                                    <th class="topo" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {PesquisaIntencaoVenda.map(ivenda => (
                                    <tr>
                                        <td>
                                            {ivenda.ve103_cod_venda}<br />
                                            <span class="email">
                                            {FormatarDataHora(ivenda.createdAt)}
                                            </span>
                                        </td>
                                        <td>
                                            {ivenda.ve103_dsc_placa}<br />
                                            <span class="email">
                                                {ivenda.ve103_num_renavam}
                                            </span>
                                        </td>
                                        <td>
                                            <a href={ivenda.ve103_url_atpve} target="_blank">{ivenda.ve103_num_atpve}</a><br />                                            
                                        </td>
                                        <td>                                            
                                            {FormatarDataHora(ivenda.ve103_dat_situacao)}<br />                                           
                                            <span class="email">
                                                {ivenda.ve103_dsc_situacao}
                                            </span>
                                        </td>
                                        <td style={{verticalAlign:'bottom'}}>
                                            <div class="dropdown">
                                                <button class="dropdown-acao btn-dropdown btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="material-symbols-outlined">more_vert</span>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li>
                                                        {ivenda.ve103_num_atpve === null &&
                                                            <a class="dropdown-item align-icon" onClick={() => RegistrarIntencaoVenda(ivenda.ve103_key_public)}>
                                                                <span class="material-symbols-outlined">send</span>Registrar
                                                            </a>
                                                        }
                                                        {ivenda.ve103_num_atpve === null &&
                                                            <a class="dropdown-item align-icon" onClick={() => Editar(ivenda.ve103_key_public)}>
                                                                <span class="material-symbols-outlined">edit</span>Editar
                                                            </a>
                                                        }

                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <UsuarioCard />
            <Paginacao esconder={true} />
        </div>
    )

}

export default IVendaTabela;