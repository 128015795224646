import MenuLateral from "../MenuLateral/menulateral";
import MenuLateralMobile from "../MenuLateral/menulateralmobile";
import Incluir from "./Incluir";
import Editar from "./Editar";
import Registrar from "./Registrar";
function Cadastro(props) {  
  
    return (
  
      <div class="container-fluid">
      <div class="row">
          <MenuLateral menu="ivenda" />
          <MenuLateralMobile />
          <div class="col-12 col-lg-2 mobile-hide invisible"></div>            
          <div id="conteudo" class="col-12 col-lg-10">
              {props.acao === "Novo" &&
                  <Incluir />
              }
              {props.acao === "Editar" &&
                  <Editar />
              }
              {props.acao === "Registrar" &&
                  <Registrar />
              }
          </div>           
      </div>
  </div>  

    );
  
  }
  
  export default Cadastro;