//import './login.css';
import MenuLateral from "../../../MenuLateral/menulateral";
import TabelaRelatoriFiliadooEmplacamento from "./tabela";


function Listagem(){    
    
    return (
        <div class="container-fluid">
            <div class="row">
                <MenuLateral menu="relFiliadoEmplacamento" admin={false}/>
                <div class="col-12 col-lg-2 mobile-hide invisible"></div>            
                <div id="conteudo" class="col-12 col-lg-10">
                    <TabelaRelatoriFiliadooEmplacamento />
                </div>           
            </div>
        </div>
   
    )
}

export default Listagem;

